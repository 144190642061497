const easy = [
    {
        id: "TMB",
        question: `Was macht der Platzwart von Berufs wegen?`,
        answers: [`An der Tube schnüffeln`, `##Ein paar Linien ziehen`, `Sich einen zwitschern`, `Eine Tüte drehen`],
    },
    {
        id: "ILD",
        question: `Wie hoch ist der berliner Fernsehturm?`,
        answers: [`126m`, `234m`, `##368m`, `486m`],
    },
    {
        id: "ZZD",
        question: `Was ist schwerer: 1kg Eisen oder 1kg Blei?`,
        answers: [`Eisen`, `Blei`, `##Keines von beiden`, `Das kann man nicht entscheiden`],
    },
    {
        id: "MCA",
        question: `Wie heißen die Spielmünzen in einem Casino?`,
        answers: [`##Jetons`, `Rabat`, `Hadisch`, `Coins`],
    },
    {
        id: "RFI",
        question: `Welches Edelmetall ist flüssig bei Raumtemperatur?`,
        answers: [`Gold`, `Silber`, `##Quecksilber`, `Platin`],
    },
    {
        id: "XJU",
        question: `Was zählt nicht zu den beliebtesten Glückssymbolen??`,
        answers: [`##rote Rosen`, `Hufeisen`, `Marienkäfer`, `vierblättriges Kleeblatt`],
    },
    {
        id: "HGO",
        question: `Worunter versteht man allgemein eine „blinde Zerstörungswut“?`,
        answers: [`Exmatrikulation`, `##Vandalismus`, `Konjugation`, `Protektionismus`],
    },
    {
        id: "YAF",
        question: `Wie nennt man weibliche Pferde?`,
        answers: [`Hengst`, `##Stute`, `Fohlen`, `Pony`],
    },
    {
        id: "JQI",
        question: `Was regnet es im Englischen, wenn es in Strömen regnet?`,
        answers: [`fish and chips`, `##cats and dogs`, `cows and sheep`, `forks and knives`],
    },
    {
        id: "ZHO",
        question: `Ein Vorzeichen bzw. eine Vorbedeutung heißt?`,
        answers: [`##Omen`, `Omega`, `Opium`, `Oman`],
    },
    {
        id: "CFK",
        question: `Eine geheime Maßnahme oder Handlung, meist unter Umgehung des Gesetzes, nennt man ... ?`,
        answers: [`Nacht-und-Mond-Aktion`, `Nacht-und-Regen-Aktion`, `Tag-und-Nacht-Aktion`, `##Nacht-und-Nebel-Aktion`],
    },
    {
        id: "MCX",
        question: `Wie viele Zähne besitzt der Mensch?`,
        answers: [`28`, `30`, `##32`, `34`],
    },
    {
        id: "ZVJ",
        question: `Wer eine knifflige/problematische Situation löst, der holt ... ?`,
        answers: [`die Ziege vom Dach`, `das Pferd aus dem Moor`, `##die Kuh vom Eis`, `das Schwein aus dem Teich`],
    },
    {
        id: "SMT",
        question: `Wer wurde von den eigenen Eltern im Wald ausgesetzt?`,
        answers: [`Max und Moritz`, `##Hänsel und Gretel`, `Die sieben Geislein`, `Schneeweißchen und Rosenrot`],
    },
    {
        id: "YUX",
        question: `Wer ist Willy im Film „Free Willy“?`,
        answers: [`Vogel`, `##Wal`, `Delphin`, `Hase`],
    },
    {
        id: "JET",
        question: `Aus welchem Bereich stammt der Begriff „Plusquamperfekt“?`,
        answers: [`Meteorologie`, `Medizin`, `##Grammatik`, `Sport`],
    },
    {
        id: "OQN",
        question: `Welche Utensilien braucht man für das Gesellschaftsspiel „Stille Post“?`,
        answers: [`Würfel`, `##Keine`, `Papier und Stift`, `Karten`],
    },
    {
        id: "CWG",
        question: `Wird unmittelbar nach dem Anpfiff sogleich ein Treffer erzielt, handelt es sich sozusagen um ein ... ?`,
        answers: [`Inspekt-Tor`, `Detekt-Tor`, `Diktat-Tor`, `##Direkt-Tor`],
    },
    {
        id: "YQI",
        question: `Wie nennt man im Sprachgebrauch eine ungewöhnliche Lösung für ein Problem?`,
        answers: [`Trick 99`, `Trick 10`, `Trick 9`, `##Trick 17`],
    },
    {
        id: "HPO",
        question: `Wobei handelt es sich um eine Redewendung und ein Synonym von „Jedermann“?`,
        answers: [`Kroos und Werner`, `##Hinz und Kunz`, `Kimmich und Reus`, `Neuer und Draxler`],
    },
    {
        id: "OKA",
        question: `Wie nennt man eine Fernleitung für den Rohrleitungstransport von Flüssigkeiten und Gasen?`,
        answers: [`##Pipeline`, `Latrine`, `CargoCap`, `Rohrpost`],
    },
    {
        id: "NDZ",
        question: `Welche Emotion beschreibt das volkstümliche Wort „Muffensausen“?`,
        answers: [`##Angst`, `Liebeskummer`, `Wut`, `Freude`],
    },
    {
        id: "KST",
        question: `Ein Rubin ist ... ?`,
        answers: [`schwarz`, `grün`, `türkis`, `##rot`],
    },
    {
        id: "NSC",
        question: `Welcher Begriff steht nicht für ein Tier, sondern ist ein Schimpfwort für einen dummen Menschen?`,
        answers: [`Brüllaffe`, `##Hornochse`, `Zitterrochen`, `Stinktier`],
    },
    {
        id: "MAD",
        question: `Was wirft man vor die Säue, wenn man etwas an jemanden vergeudet?`,
        answers: [`Eier`, `Pralinen`, `##Perlen`, `Kartoffeln`],
    },
    {
        id: "DCA",
        question: `Was ist ein Adjektiv?`,
        answers: [`##Eigenschaftswort`, `Zeitangabe`, `Artikel`, `Wortsteigerung`],
    },
    {
        id: "NCW",
        question: `Wie bezeichnet man populärsprachlich überfürsorgliche Eltern?`,
        answers: [`Glucken-Eltern`, `Standby-Eltern`, `Permanent-Eltern`, `##Helikopter-Eltern`],
    },
    {
        id: "CXK",
        question: `Welcher Film ist die Fortsetzung von „Findet Nemo“?`,
        answers: [`Findet Julia`, `Findet Mare`, `##Findet Dorie`, `Findet Josi`],
    },
    {
        id: "NZP",
        question: `Welcher Teil des menschlichen Körpers enthält mehrere hundert Millionen winziger Bläschen?`,
        answers: [`##Lunge`, `Niere`, `Milz`, `Herz`],
    },
    {
        id: "ZWC",
        question: `Wie nennt man das Ortungsverfahren im Raum und unter Wasser mittels Schallimpulsen?`,
        answers: [`##Sonar`, `Solar`, `Polar`, `Modular`],
    },
    {
        id: "HHZ",
        question: `Was verbindet das Schulterblatt mit dem Brustbein?`,
        answers: [`Kreuzbein`, `Steißbein`, `##Schlüsselbein`, `Jochbein`],
    },
    {
        id: "GZD",
        question: `Als Ausdruck der Verwunderung sagt man: „Holla ...“?`,
        answers: [`der Butzemann`, `der Rasselbock`, `der Flaschengeist`, `##die Waldfee`],
    },
    {
        id: "HAP",
        question: `Das plötzliche Wiedererleben eines vergangenen Erlebnisses bezeichnet man als ... ?`,
        answers: [`##Flashback`, `Flashscore`, `Flashdance`, `Flashmob`],
    },
    {
        id: "AGL",
        question: `Welcher Planet wird aufgrund des gut sichtbaren Ringsystems auch „Ringplanet“ genannt?`,
        answers: [`Merkur`, `##Saturn`, `Mars`, `Jupiter`],
    },
    {
        id: "OQM",
        question: `Wozu gehört ein „Campus“?`,
        answers: [`##Universität`, `Campingplatz`, `Ministerium`, `Kirche`],
    },
    {
        id: "RVC",
        question: `Wie heißt ein Ideogramm, das insbesondere in SMS und Chats längere Begriffe ersetzt?`,
        answers: [`Eloji`, `##Emoji`, `Ekoji`, `Enoji`],
    },
    {
        id: "NEJ",
        question: `Wenn jemand  „versiert“ ist, dann ist er ... ?`,
        answers: [`aufdringlich`, `##erfahren`, `verletzlich`, `traurig`],
    },
    {
        id: "PEF",
        question: `Was hat Mary Poppins immer dabei, wenn sie fliegt?`,
        answers: [`Pudel und Fahrrad`, `Zuckerdose und Löffelchen`, `##Tasche und Regenschirm`, `Teekanne und Kuchen`],
    },
    {
        id: "YUD",
        question: `Ein Spielball, der die obere Kante des Netzes berührt und ins gegnerische Spielfeld geht, heißt ... ?`,
        answers: [`Luftroller`, `##Netzroller`, `Federroller`, `Maschenroller`],
    },
    {
        id: "RSZ",
        question: `Wie lautet der Name des bis heute weltweit meistverkauften Musikalbums von Michael Jackson?`,
        answers: [`##Thriller`, `Drama`, `Horror`, `Action`],
    },
    {
        id: "FNV",
        question: `Wie heißt ein Lied der deutschen Band „Fools Garden“?`,
        answers: [`Rubber Tree`, `Cherry Tree`, `Apple Tree`, `##Lemon Tree`],
    },
    {
        id: "UQJ",
        question: `Welcher Augenteil gibt dem Auge die Farbe?`,
        answers: [`Hornhaut`, `Pupille`, `##Iris`, `Netzhaut`],
    },
    {
        id: "KXP",
        question: `Wofür ist die Nase zuständig, wenn man den Anfangsbuchstaben entfernt?`,
        answers: [`Portugiesen`, `Kroaten`, `Italiener`, `##Griechen`],
    },
    {
        id: "CDK",
        question: `Ein Zeichentrickfilm von Walt Disney heißt „101 ...“?`,
        answers: [`Foxterrier`, `Schäferhunde`, `Pudel`, `##Dalmatiner`],
    },
    {
        id: "PCU",
        question: `Welche Temperaturskala ist in den USA üblich?`,
        answers: [`Celsius`, `Kelvin`, `Grad`, `##Fahrenheit`],
    },
    {
        id: "RYA",
        question: `Wie wird das Eigelb noch genannt?`,
        answers: [`Eiweiß`, `Pelle`, `##Dotter`, `Kern`],
    },
    {
        id: "WYI",
        question: `Welche schädliche Wirkung hat das „Ozonloch“?`,
        answers: [`Vermehrte Radioaktivität`, `Vermehrte Luftverschmutzung`, `##Vermehrte Ultraviolettstrahlung`, `Vermehrte Infrarotstrahlung`],
    },
    {
        id: "QPC",
        question: `Welches Gas atmen Pflanzen ein und wandeln es in Sauerstoff um?`,
        answers: [`##Kohlenstoffdioxid`, `Stickstoff`, `Sauerstoff`, `Wasserstoff`],
    },
    {
        id: "NBK",
        question: `Wie heißt die Zahl unter dem Bruchstrich?`,
        answers: [`Divisor`, `##Nenner`, `Zähler`, `Quotient`],
    },
    {
        id: "FFB",
        question: `Was bedeutet „Paroli bieten“?`,
        answers: [`Zur Sache kommen`, `In der Mitte stehen`, `##Widerstand entgegensetzen`, `Zeitgemäß oder auf dem Laufenden sein`],
    },
    {
        id: "OBS",
        question: `Wie wird in Deutschland umgangssprachlich die Abkürzung „MPU“ genannt?`,
        answers: [`##Idiotentest`, `Mutprobe`, `Misswahl`, `Flatrate-Saufen`],
    },
    {
        id: "GTS",
        question: `Wie wird die Rügensche Kleinbahn im Volksmund genannt?`,
        answers: [`Rasender Donald`, `Rasender Paul`, `##Rasender Roland`, `Rasender Willy`],
    },
    {
        id: "VYW",
        question: `Was bedeutet das Sprichwort: „Jemandem einen Bären aufbinden“?`,
        answers: [`Arbeit überhelfen`, `etwas verschweigen`, `bloß stellen`, `##etwas vormachen / anlügen`],
    },
    {
        id: "HEF",
        question: `Was bedeutet das Sprichwort: „Nah am Wasser gebaut sein“?`,
        answers: [`##sensibel sein`, `vorbereitet sein`, `intelligent sein`, `zuversichtlich sein`],
    },
    {
        id: "OBK",
        question: `Was bedeutet das Sprichwort: „Sein Fett weg bekommen“`,
        answers: [`Ein Lob bekommen`, `Eine Belohnung bekommen`, `##Eine Strafe bekommen`, `Eine Last los bekommen`],
    },
    {
        id: "EWC",
        question: `Wie heißt das Sprichwort: „Das sind ... Dörfer für mich“`,
        answers: [`römische`, `griechische`, `fremde`, `##böhmische`],
    },
    {
        id: "EWB",
        question: `Wie heißt das Sprichwort: „Lieber den Spatz in der Hand als ... auf dem Dach“`,
        answers: [`die Krähe`, `##die Taube`, `den Spatz`, `den Geier`],
    },
    {
        id: "JPD",
        question: `Wie heißt das Sprichwort: „Schuster, bleib bei deinen ...“`,
        answers: [`Werk`, `Kleidern`, `##Leisten`, `Schuhen`],
    },
    {
        id: "ZBV",
        question: `Wie heißt das Sprichwort: „Du hast die Weisheit nicht mit ... gefressen“`,
        answers: [`Messern`, `Gabeln`, `Tellern`, `##Löffeln`],
    },
    {
        id: "OQY",
        question: `Wie heißt das Sprichwort: „Unter den Blinden ist der Einäugige ...“`,
        answers: [`##König`, `Pirat`, `Kaiser`, `im Vorteil`],
    },

    
    // {
    //     id: "OIM",
    //     question: `Wie heißt das Sprichwort: „In den ... Apfel beißen“`,
    //     answers: [`##sauren`, `süßen`, `bitteren`, `salzigen`],
    // },
    // {
    //     id: "SAB",
    //     question: `Wie heißt das Sprichwort: „Sich etwas aus ... schütteln“`,
    //     answers: [`der Jacke`, `der Hose`, `dem Kragen`, `##dem Ärmel`],
    // },
    // {
    //     id: "PVU",
    //     question: `Wie heißt das Sprichwort: „Auf keinen ... kommen“`,
    //     answers: [`##grünen Zweig`, `langen Ast`, `hohen Baum`, `hohen Zweig`],
    // },
    // {
    //     id: "DMI",
    //     question: `Wie heißt das Sprichwort: „Jemandem nicht ... reichen können“`,
    //     answers: [`den Ball`, `den Tee`, `##das Wasser`, `den Schlüssel`],
    // },
    // {
    //     id: "ZZS",
    //     question: `Wie heißt das Sprichwort: „In der Not frisst der Teufel ...“`,
    //     answers: [`##Fliegen`, `Brot`, `wenig`, `Käfer`],
    // },
    // {
    //     id: "ZRR",
    //     question: `Wie heißt das Sprichwort: „Das Auge ist das Fenster ...“`,
    //     answers: [`zur Hölle`, `zu Gott`, `zur Welt`, `##zur Seele`],
    // },
    // {
    //     id: "FAB",
    //     question: `Wie heißt das fliegende Ross der griechischen Mythologie?`,
    //     answers: [`##Pegasus`, `Daedalus`, `Vegasos`, `Dadalus`],
    // },
    // {
    //     id: "TLV",
    //     question: `Wie nennt man übertriebene Schauspielerei?`,
    //     answers: [`##Theatralik`, `Pathetik`, `Mimik`, `Rhetorik`],
    // },
    // {
    //     id: "ODH",
    //     question: `Was ist eine Gürtelrose?`,
    //     answers: [`Blume`, `##Krankheit`, `Gürtelverzierung`, `Einzeller`],
    // },
    // {
    //     id: "KWC",
    //     question: `Wieviel Stück hat ein Dutzend?`,
    //     answers: [`6`, `10`, `##12`, `15`],
    // },
    // {
    //     id: "CNV",
    //     question: `Was ist Chlorophyll?`,
    //     answers: [`Blutfarbstoff`, `Blattgelb`, `Blattrot`, `##Blattgrün`],
    // },
    // {
    //     id: "CEV",
    //     question: `Wie viele Monate umfasst ein Quartal?`,
    //     answers: [`2`, `##3`, `4`, `6`],
    // },
    // {
    //     id: "QXC",
    //     question: `Wie hießen die Wettkämpfe der Ritter?`,
    //     answers: [`Schach`, `Schlacht`, `##Turnier`, `Kampf`],
    // },
];

export default easy;