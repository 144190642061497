import React, {Component} from "react";
import {observer} from "mobx-react";
import {motion, AnimatePresence} from "framer-motion";

import global from "./Global";
import effect from "../js/animation";
import {chooseAnswer} from "../js/game";

class Answer extends Component {
    render() {
        const {game, teams, round, control, develop} = global;

        return (
            <AnimatePresence>
            {
            control.answerContainer &&
                <motion.div
                    id="answer-container"
                    variants={effect.answerContainer}
                    initial="init"
                    animate="in"
                    exit="out"
                    onAnimationStart={() => {
                        if (game.status === "final") {
                            global.control.promptText = `${round.team?.players?.join(", ")} ...<br/>${(round.team?.players?.length === 1) ? "Wähle deine Antwort verdeckt" : "Wählt eure Antwort verdeckt"}`;
                        }
                    }}
                    onAnimationComplete={(type) => {
                        const teamsInFinal = teams.filter(team => team.final);

                        if (type === "in") {
                            if (round.question?.genre === "personal" || (game.status === "final" && teamsInFinal.length > 1)) {
                                global.control.prompt = true;
                                setTimeout(() => { global.game.allowInput = true }, 1200);
                            } else {
                                global.game.allowInput = true;
                                global.control.jokerIndicator = true;
                            }
                        }
                        if (type === "out") {
                            if (game.status === "final" && round.answer.final.length >= teamsInFinal.length) {
                                global.control.finalEvaluation = true;
                            }
                        }
                    }}
                >
                    <div className="flex-row">
                        <div
                            className={
                                "answer" +
                                `${round.answer.choosen === 0 ? " choosen" : ""}` +
                                `${round.answer.correct === 0 ? " correct" : ""}` +
                                `${round.jokerAnswers.includes(0) ? " hidden" : ""}` +
                                `${round.question?.genre === "personal" && round.answer.personal === null ? " open" : ""}` +
                                `${round.answer.correct !== null && round.answer.choosen === 0 && round.answer.correct !== round.answer.choosen ? " wrong" : ""}`
                            }
                            onClick={() => chooseAnswer(0)}
                        >
                            <span>1)</span>
                            <motion.p variants={effect.answer}>
                            {
                                (develop)
                                    ? round.question?.answers[0].match(/^##/) || ""
                                    : round.question.answers[0]?.replace(/^##/, "")
                            }
                            </motion.p>
                        </div>
                        <div
                            className={
                                "answer" +
                                `${round.answer.choosen === 1 ? " choosen" : ""}` +
                                `${round.answer.correct === 1 ? " correct" : ""}` +
                                `${round.jokerAnswers.includes(1) ? " hidden" : ""}` +
                                `${round.question?.genre === "personal" && round.answer.personal === null ? " open" : ""}` +
                                `${round.answer.correct !== null && round.answer.choosen === 1 && round.answer.correct !== round.answer.choosen ? " wrong" : ""}`
                            }
                            onClick={() => chooseAnswer(1)}
                        >
                            <span>2)</span>
                            <motion.p variants={effect.answer}>
                            {
                                (develop)
                                    ? round.question?.answers[1].match(/^##/) || ""
                                    : round.question.answers[1]?.replace(/^##/, "")
                            }
                            </motion.p>
                        </div>
                    </div>
                    {
                    round.question?.answers?.length >= 3 &&
                        <div className="flex-row">
                            <div
                                className={
                                    "answer" +
                                    `${round.answer.choosen === 2 ? " choosen" : ""}` +
                                    `${round.answer.correct === 2 ? " correct" : ""}` +
                                    `${round.jokerAnswers.includes(2) ? " hidden" : ""}` +
                                    `${round.question?.genre === "personal" && round.answer.personal === null ? " open" : ""}` +
                                    `${round.answer.correct !== null && round.answer.choosen === 2 && round.answer.correct !== round.answer.choosen ? " wrong" : ""}`
                                }
                                onClick={() => chooseAnswer(2)}
                            >
                                <span>3)</span>
                                <motion.p variants={effect.answer}>
                                {
                                    (develop)
                                        ? round.question?.answers[2].match(/^##/) || ""
                                        : round.question.answers[2]?.replace(/^##/, "")
                                }
                                </motion.p>
                            </div>
                            {
                            round.question?.answers?.length >= 4 &&
                                <div
                                    className={
                                        "answer" +
                                        `${round.answer.choosen === 3 ? " choosen" : ""}` +
                                        `${round.answer.correct === 3 ? " correct" : ""}` +
                                        `${round.jokerAnswers.includes(3) ? " hidden" : ""}` +
                                        `${round.question?.genre === "personal" && round.answer.personal === null ? " open" : ""}` +
                                        `${round.answer.correct !== null && round.answer.choosen === 3 && round.answer.correct !== round.answer.choosen ? " wrong" : ""}`
                                    }
                                    onClick={() => chooseAnswer(3)}
                                >
                                    <span>4)</span>
                                    <motion.p variants={effect.answer}>
                                    {
                                        (develop)
                                            ? round.question?.answers[3].match(/^##/) || ""
                                            : round.question.answers[3]?.replace(/^##/, "")
                                    }
                                    </motion.p>
                                </div>
                            }
                        </div>
                    }
                </motion.div>
            }
            </AnimatePresence>
        );
    }
}

export default observer(Answer);