import React, {Component} from "react";
import {observer} from "mobx-react";
import {motion} from "framer-motion";

import global from "./Global";
import effect from "../js/animation";
import {nextRound, winner} from "../js/game";
import {play, saveData} from "../js/functions";

class FinalEvaluation extends Component {
    evaluateAnswers() {
        const {teams, develop} = global;
        const teamsInFinal = teams.filter(team => team.final);
    
        setTimeout(() => {
            global.control.animation.finalAnswerChoosen = true;
            play("choosen");
    
            setTimeout(() => {
                const answers = this.checkFinalAnswers();
                for (let i = 0; i < teamsInFinal.length; i++) global.control.animation.finalAnswer.push(null);

                if (answers.correct.length === teamsInFinal.length) {
                    this.setFinalAnswer(answers, "correct");
                } else if (answers.wrong.length === teamsInFinal.length) {
                    this.setFinalAnswer(answers, "wrong");
                    global.control.finalCorrectAnswer = true;
                } else {
                    this.setFinalAnswer(answers, "correct", true);
                    setTimeout(() => { this.setFinalAnswer(answers, "wrong") }, 1500);
                }
            }, (develop) ? 500 : 2500);
        }, (develop) ? 500 : 3000);
    }

    async setFinalAnswer(answers, type, stop = false) {
        const {teams} = global;
        const teamsInFinal = teams.filter(team => team.final);

        answers[type].forEach(teamIndex => global.control.animation.finalAnswer[teamIndex] = type);
        play(type);

        if (!stop) {
            setTimeout(() => {
                if (answers.correct.length === 1) {
                    play("clappingFinal");
                } else if (answers.correct.length === teamsInFinal.length) {
                    play("clapping");
                } else if (answers.wrong.length === teamsInFinal.length) {
                    play("aww");
                } else {
                    play("clapping");
                }

                setTimeout(() => { global.control.finalEvaluation = false }, 5000);
            }, 500);
        }
    }

    checkFinalAnswers () {
        const {teams, round} = global;
        const teamsInFinal = teams.filter(team => team.final);
        const correctIndex = round.question.answers.findIndex(x => x.match(/^##/));
        const correct = [];
        const wrong = [];

        for (let i = 0; i < teamsInFinal.length; i++) {
            const answerIndex = global.round.answer.final[i];
            const correctAnswers = (answerIndex === correctIndex) ? correct : wrong;
            correctAnswers.push(i);
        }

        return { correct, wrong };
    }

    render() {
        const {teams, round, control} = global;
        const teamsInFinal = teams.filter(team => team.final);

        const correctIndex = round.question?.answers?.findIndex(x => x.match(/^##/));
        const correctFinalAnswer = (correctIndex >= 0) ? round.question.answers[correctIndex] : null;
        
        return (
            <motion.div
                id="final-evaluation"
                variants={effect.finalEvaluation}
                initial="init"
                animate="in"
                exit="out"
                onAnimationComplete={(type) => {
                    if (type === "in") this.evaluateAnswers();
                    if (type === "out") {
                        const answers = this.checkFinalAnswers();

                        if (answers.correct.length >= 2 && answers.wrong.length > 0) {
                            for (const index of answers.wrong) {
                                const teamIndex = teams.indexOf(teamsInFinal[index]);
                                global.teams[teamIndex].final = false;
                                saveData("teams");
                            }
                        }

                        if (answers.correct.length === 1) {
                            const winnerIndex = answers.correct[0];
                            winner(teamsInFinal[winnerIndex]);
                        } else {
                            nextRound();
                        }
                    }
                }}
            >
                <h1>
                    <i className="fas fa-trophy"></i>
                    Auswertung
                </h1>
                <h2>{ round.question.question }</h2>
                <div className="flex-row">
                {
                    teamsInFinal.map((team, teamFinalIndex) => {
                        const choosenAnswer = {
                            answer: round.question.answers.find((x, i) => i === round.answer.final[teamFinalIndex]),
                            number: round.question.answers.findIndex((x, i) => i === round.answer.final[teamFinalIndex]) + 1,
                        }

                        return (
                            <motion.div key={teamFinalIndex} className="final-evaluation-team" variants={effect.finalEvaluationAnswer}>
                                <h1>{ team.players?.join(", ") }</h1>
                                <div
                                    className={
                                        "answer" +
                                        `${(control.animation.finalAnswerChoosen) ? " choosen" : ""}` +
                                        `${(control.animation.finalAnswer[teamFinalIndex] === "correct") ? " correct" : ""}` +
                                        `${(control.animation.finalAnswer[teamFinalIndex] === "wrong") ? " wrong" : ""}`
                                    }
                                >
                                    <span>{ choosenAnswer.number })</span>
                                    <p>{ choosenAnswer.answer?.replace(/^##/, "") }</p>
                                </div>
                            </motion.div>
                        );
                    })
                }
                </div>
                {
                control.finalCorrectAnswer &&
                    <motion.div id="final-evaluation-correct-answer" variants={effect.finalCorrectAnswer}>
                        <h1>Richtige Antwort</h1>
                        <div className="answer correct">
                            <span>{ (correctIndex >= 0) ? correctIndex + 1 : null})</span>
                            <p>{ correctFinalAnswer?.replace(/^##/, "") }</p>
                        </div>
                    </motion.div>
                }
            </motion.div>
        );
    }
}

export default observer(FinalEvaluation);