import JSConfetti from "js-confetti";

import global from "../components/Global";
import questions from "../questions";
import effect from "../js/animation";
import {getNextTeamIndex, getFirstTeamIndex, shuffle, random, saveData, play} from "../js/functions";

const jsConfetti = new JSConfetti();

console.log("All:", questions);

//############################################################################# Question

function chooseQuestion() {
    const {game} = global;
    const openQuestions = questions.all.filter(x => !game.questions.includes(x.id));
    const shuffled = shuffle(openQuestions);
    const allowed = allowedQuestions(shuffled);
    const question = (game.status === "demo") ? questions.demo[game.move] : random(allowed);

    if (game.status === "demo" && game.move >= questions.demo.length) {
        reset();
        return null;
    }

    if (!question) {
        window.alert("Keine Fragen an dieser Stelle mehr verfügbar! Setze die gespielten Fragen zurück.");
        return null;
    }

    if (question.answers?.length > 0 && question.type === "classic") {
        question.answers = shuffle(question.answers);
    }

    if (question.genre === "personal") {
        global.round.personalPlayer = choosePlayer();
        global.control.personalPlayer = true;
        global.game.notPersonalCounter = 0;
    } else {
        global.control.question = true;
        global.game.notPersonalCounter ++;
    }

    return question;
}

function choosePlayer() {
    const {round, teams} = global;
    const allowedTeams = teams.filter(x => x !== round.team);
    let names = [];

    for (const team of allowedTeams) names.push(...team.players);

    if (round.team.lastPersonalPlayer && names.length > 1) {
        names = names.filter(name => name !== round.team.lastPersonalPlayer);
    }

    const randomPlayer = random(names);
    global.round.team.lastPersonalPlayer = randomPlayer;
    
    return randomPlayer;
}

function allowedQuestions(list = []) {
    const {game, round, settings} = global;

    const available = {
        personal: list.filter(x => x.genre === "personal"),
        easy: list.filter(x => x.genre === "easy"),
        knowledge: list.filter(x => x.genre === "knowledge"),
        difficult: list.filter(x => x.genre === "difficult"),
        image: list.filter(x => x.genre === "image"),
    }

    console.log("Available:", available);

    return list.filter(x => {
        if (game.status === "final") {
            if (available.difficult.length > 0) {
                if (x.genre !== "difficult") return false;
            } else if (available.knowledge.length > 0) {
                if (x.genre !== "knowledge") return false;
            } else if (available.image.length > 0) {
                if (x.genre !== "image") return false;
            } else {
                return false;
            }
        } else if (game.status === "match") {
            if (round.team?.score <= 0) {
                if (available.easy.length > 0) {
                    if (x.genre !== "easy") return false;
                } else if (available.knowledge.length > 0) {
                    if (x.genre !== "knowledge") return false;
                } else if (available.image.length > 0) {
                    if (x.genre !== "image") return false;
                } else {
                    return false;
                }
            } else {
                if (round.team.personalCounter >= settings.maxPersonalPerTeam) {
                    if (x.genre === "personal" && (available.knowledge.length > 0 || available.image.length > 0)) return false;
                } else if (game.notPersonalCounter >= settings.forcePersonalQuestion - 1) {
                    if (x.genre !== "personal" && available.personal.length > 0) return false;
                }
                if (
                    (x.genre === "difficult" || x.genre === "easy") &&
                    (available.knowledge.length > 0 || available.image.length > 0 || available.personal.length > 0)
                ) {
                    return false;
                }
            }
        }
        return true;
    });
}

//############################################################################# Answer

function chooseAnswer(index) {
    const {game, teams, round, control, develop} = global;
    
    if (
        index > round.question?.answers?.length - 1 ||
        round.jokerAnswers.includes(index) ||
        control.joker ||
        round.answer.choosen !== null ||
        game.status === "start" ||
        !game.allowInput ||
        control.settings
    ) {
        return;
    }
    
    if (round.question.genre === "personal" && global.round.answer.personal === null) {
        global.game.allowInput = false;
        global.round.answer.personal = index;
        global.control.jokerIndicator = true;
        global.control.promptText = `${round.team?.players?.join(", ")} ...<br/>Wie hat ${round.personalPlayer} sich entschieden?`;
        setTimeout(() => { global.game.allowInput = true }, 2000);
        play("logged");
        return;
    }

    const correctIndex =
        (round.question.genre === "personal")
            ? global.round.answer.personal
            : round.question.answers.findIndex(x => x.match(/^##/));
    
    if (game.status === "final") {
        const teamsInFinal = teams.filter(team => team.final);

        if (teamsInFinal.length > 1) {
            global.round.answer.final.push(index);
            play("logged");
            global.game.allowInput = false;

            if (global.round.answer.final.length >= teamsInFinal.length) {
                global.round.team = null;
                setTimeout(() => {
                    global.control.question = false;
                    global.control.imagePreview = false;
                    global.control.answerContainer = false;
                    global.control.personalPlayer = false;
                    global.control.finalCorrectAnswer = false;
                    global.control.prompt = false;
                    global.control.jokerIndicator = false;
                    
                    if (!game.questions.includes(round.question?.id)) global.game.questions.push(round.question?.id || "");
                    saveData("questions");
                }, 1500);
            } else {
                global.round.team = teams[getNextTeamIndex("final")];
                global.control.promptText = `${round.team?.players?.join(", ")} ...<br/>${(round.team?.players?.length === 1) ? "Wähle deine Antwort verdeckt" : "Wählt eure Antwort verdeckt"}`;
                saveData("turn");
                setTimeout(() => { global.game.allowInput = true }, 2000);
            }

            return;
        }
    }
    
    global.game.allowInput = false;
    global.round.answer.choosen = index;

    play("choosen");

    setTimeout(() => {
        let win = false;
        global.round.answer.correct = correctIndex;
        if (round.question.genre === "personal") global.round.team.personalCounter ++;

        if (index === correctIndex) {
            win = true;
            play("correct");
            setTimeout(() => { play("clapping") }, 500);
        } else {
            play("wrong");
            setTimeout(() => { play("aww") }, 500);
        }

        if (game.status !== "demo") {
            if (!game.questions.includes(round.question?.id)) global.game.questions.push(round.question?.id || "");
            saveData("questions");
        }

        setTimeout(() => { progress(win) }, (develop) ? 500 : 5000);
    }, (develop) ? 500 : 2500);
}

//############################################################################# Progress

function progress(win = false) {
    const {game, settings, round, control, develop} = global;

    global.control.question = false;
    global.control.imagePreview = false;
    global.control.answerContainer = false;
    global.control.personalPlayer = false;
    global.control.prompt = false;
    global.control.jokerIndicator = false;

    setTimeout(() => {
        if (win) {
            global.round.team.score ++;
            if (round.team?.score === settings.scoreMax - 1) global.round.team.final = true;
            control.animation.player.start({ ...effect.player[settings.theme].win });
            play("win");
        } else {
            control.animation.player.start({ ...effect.player[settings.theme].lose });
            play("lose");
        }

        if (game.status !== "demo") saveData("teams");
    }, (develop) ? 500 : 1000);
}

//############################################################################# Joker

function useJoker() {
    const {game, round, settings} = global;
    if (round.question?.answers?.length < settings.allowJokerFromAnswers || round.jokerUsed || round.team?.joker <= 0 || round.answer.choosen !== null || !game.allowInput) return;
    if (game.status === "start" || game.status === "final") return;
    if (round.question?.genre === "personal" && round.answer.personal === null) return;

    global.round.team.joker --;
    global.round.jokerUsed = true;
    global.control.joker = true;
    play("joker");
}

function jokerEffect() {
    const {round} = global;
    const correctIndex =
        (round.question.genre === "personal")
            ? global.round.answer.personal
            : round.question.answers.findIndex(x => x.match(/^##/));
        
    const answers = round.question.answers.filter((x, i) => i !== correctIndex);
    const keep = random(answers);
    const remove = answers.filter(x => x !== keep);

    global.round.jokerAnswers = remove.map(x => round.question.answers.indexOf(x));
    global.control.joker = false;
}

//############################################################################# Image Preview

function toggleImagePreview() {
    const {round, control} = global;

    if (round.question?.genre === "image") {
        global.control.imagePreview = !control.imagePreview;
    }
}

//############################################################################# Winner

function winner(winnerTeam) {
    const {teams, control, settings} = global;
    const teamsInFinal = teams.filter(team => team.final);
    let confettiCounter = 0;

    global.game.winner = winnerTeam;
    global.round.team = winnerTeam;

    if (teamsInFinal.length > 1) {
        setTimeout(() => { play("clappingFinal") }, 1000);
    } else {
        play("clappingFinal");
    }

    setTimeout(() => {
        if (teamsInFinal.length > 1) {
            global.round.team.score ++;
            control.animation.player.start({ ...effect.player[settings.theme].win });
            play("win");
        }

        const confettiInterval = setInterval(() => {
            jsConfetti.addConfetti({
                confettiRadius: 6,
                confettiNumber: 300,
            });

            confettiCounter ++;

            if (confettiCounter >= 3) clearInterval(confettiInterval);
        }, 1500);
    }, 2000);
}

//############################################################################# Next Round

function nextRound() {
    const {game, teams, round, settings} = global;
    const teamsInFinal = teams.filter(team => team.final);
    const teamsInMatch = teams.filter(team => !team.final);
    const teamMatchIndex = teamsInMatch.indexOf(round.team);
    let nextTeamIndex = getNextTeamIndex((teamsInFinal.length > 0) ? "match" : null);

    if (game.status === "match") {
        if (teamMatchIndex === teamsInMatch.length - 1 || nextTeamIndex === null) {
            if (teamsInFinal.length >= 1) {
                global.game.status = "final";
                global.control.final = true;
                nextTeamIndex = getFirstTeamIndex("final");
            }
        }
    } else {
        if (game.status === "final") {
            if (teamsInFinal.length === 1) {
                if (round.team?.score >= settings.scoreMax) {
                    winner(round.team);
                    return;
                } else {
                    global.game.status = "match";
                    nextTeamIndex = getFirstTeamIndex("match");
                }
            } else {
                nextTeamIndex = getFirstTeamIndex("final");
            }
        }
    }

    global.game.allowInput = false;
    global.round.answer.personal = null;
    global.round.answer.choosen = null;
    global.round.answer.correct = null;
    global.round.answer.final = [];
    global.round.jokerUsed = false;
    global.round.jokerAnswers = [];
    global.round.team = teams[nextTeamIndex];
    global.control.promptText = false;
    global.control.progressFinal = false;
    global.control.animation.finalAnswerChoosen = false;
    global.control.animation.finalAnswer = [];

    if (game.status !== "demo") saveData("turn");
    if (global.control.final) return;

    global.game.move ++;

    setTimeout(() => {
        global.round.question = chooseQuestion();
    }, 1500);
}

//############################################################################# Reset

function reset(type) {
    const {game, teams, settings} = global;

    if (type === "questions") {
        const confirmed = window.confirm(`Wirklich alle ${game.questions.length} gespeicherten Fragen, die bisher gespielt wurden, zurücksetzen?`);

        if (confirmed) {
            localStorage.removeItem("quizQuestions");
            saveData("teams");
            window.alert(`${game.questions.length} Fragen wurden zurückgesetzt. ${questions.all.length} Fragen wieder verfügbar.`);
            return;
        } else {
            return;
        }
    }

    if (type === "teams") {
        const confirmed = window.confirm("Wirklich den gespeicherten Spielstand aller Teams zurücksetzen?");

        if (confirmed) {
            for (const team of teams) {
                team.score = 0;
                team.personalCounter = 0;
                team.joker = settings.jokerPerTeam;
                team.final = false;
                team.lastPersonalPlayer = null;
            }
            saveData("teams");
        } else {
            return;
        }
    }

    global.game.status = "start";
    global.game.allowInput = false;
    global.game.winner = null;
    global.game.notPersonalCounter = 0;
    global.game.move = 0;

    global.round.team = null;
    global.round.question = null;
    global.round.personalPlayer = null;
    global.round.answer.personal = null;
    global.round.answer.choosen = null;
    global.round.answer.correct = null;
    global.round.answer.final = [];
    global.round.jokerAnswers = [];
    global.round.jokerUsed = false;

    global.control.question = false;
    global.control.imagePreview = false;
    global.control.answerContainer = false;
    global.control.answers = false;
    global.control.prompt = false;
    global.control.promptText = false;
    global.control.joker = false;
    global.control.jokerIndicator = false;
    global.control.progressFinal = false;
    global.control.final = false;
    global.control.finalEvaluation = false;
    global.control.finalWinner = false;
    global.control.finalCorrectAnswer = false;
    global.control.animation.finalAnswerChoosen = false;
    global.control.animation.finalAnswer = [];

    for (const team of teams) {
        team.score = 0;
        team.personalCounter = 0;
        team.joker = settings.jokerPerTeam;
        team.final = false;
        team.lastPersonalPlayer = null;
    }

    saveData("turn");
}

//############################################################################# Export

export {chooseQuestion, chooseAnswer, useJoker, jokerEffect, toggleImagePreview, nextRound, winner, reset};