import personal from "./personal";
import easy from "./easy";
import knowledge from "./knowledge";
import difficult from "./difficult";
import image from "./image";
import demo from "./demo";

//############################################################################# Prepare All Questions

const all = [];

// Generate ids for all questions
// question.id = randomId();

for (const question of personal) {
    question.genre = "personal";

    if (question.type === "scale") question.answers = [`0-3`, `4-6`, `7-10`];
    if (question.type === "choice") question.answers = [`Ja`, `Nein`];
    if (question.type === "rating") question.answers = [`Sehr schlecht`, `Eher schlecht`, `Eher gut`, `Besonders gut`];

    all.push(question);
}

for (const question of easy) {
    question.genre = "easy";
    question.type = "classic";

    if (!check(question)) continue;

    all.push(question);
}

for (const question of knowledge) {
    question.genre = "knowledge";
    question.type = "classic";

    if (!check(question)) continue;

    all.push(question);
}

for (const question of difficult) {
    question.genre = "difficult";
    question.type = "classic";

    if (!check(question)) continue;

    all.push(question);
}

for (const question of image) {
    question.genre = "image";
    question.type = "classic";

    if (!check(question)) continue;

    all.push(question);
}

for (const question of demo) {
    if (question.genre === "personal") {
        if (question.type === "scale") question.answers = [`0-3`, `4-6`, `7-10`];
        if (question.type === "choice") question.answers = [`Ja`, `Nein`];
        if (question.type === "rating") question.answers = [`Sehr schlecht`,`Eher schlecht`,`Eher gut`,`Besonders gut`];
    } else {
        if (!check(question)) continue;
    }
}

//############################################################################# Check

function check(question) {
    const matches = question.answers?.map(x => x.match(/^##/) !== null ? 1 : 0);

    if (!matches.includes(1)) {
        console.error(`Question without correct answer: `, question);
        return false;
    }

    if (matches.reduce((a, b) => a + b, 0) > 1) {
        console.error(`Question with more than one correct answer: `, question);
        return false;
    }

    return true;
}

//############################################################################# Random ID

function randomId(idLength = 3) {
    const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

    for (let j = 0; j < 1000; j++) {
        let id = "";

        for (let i = 0; i < idLength; i++) {
            const randomNumber = Math.floor(Math.random() * chars.length);
            id += chars.charAt(randomNumber);
        }

        if (!all.map(x => x.id).includes(id)) {
            return id;
        }
    }
}

//############################################################################# Export

console.log(randomId());

const exportArray = {personal, easy, knowledge, difficult, image, demo, all};

export default exportArray;