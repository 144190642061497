const image = [
    {
        id: "HVB",
        question: `Aus welchem Jahr stammt diese Fotografie vom Berliner Alexanderplatz?`,
        answers: [`1955`, `1945`, `##1935`, `1925`],
    },
    {
        id: "TUV",
        question: `Welches Kraut ist hier abgebildet?`,
        answers: [`Oregano`, `Brennnessel `, `##Zitronenmelisse`, `Thymian`],
    },
    {
        id: "UER",
        question: `Welches Heilkraut ist hier abgebildet?`,
        answers: [`Haugenblume`, `##Kamille`, `Mutterkraut`, `Sagenkraut`],
    },
    {
        id: "EWK",
        question: `In welcher Stadt entstand diese Aufnahme?`,
        answers: [`Berlin`, `Hamburg`, `##Köln`, `Leipzig`],
    },
    {
        id: "DYD",
        question: `Welche Stadt wird gezeigt?`,
        answers: [`##Los Angeles`, `New York City`, `San Francisco`, `San Diego`],
    },
    {
        id: "MUS",
        question: `Von welcher Automarke stammt dieses Fahrzeug?`,
        answers: [`BMW`, `Mercedes Benz`, `Volkswagen`, `##Audi`],
    },
    {
        id: "BWU",
        question: `Von welcher Automarke stammt dieses Fahrzeug?`,
        answers: [`##Opel`, `Škoda`, `Volkswagen`, `Mazda`],
    },
    {
        id: "TNU",
        question: `Welches Organ ist abgebildet?`,
        answers: [`##Milz`, `Magen`, `Leber`, `Bauchspeicheldrüse`],
    },
    {
        id: "XZZ",
        question: `Welches Unternehmen ist gesucht?`,
        answers: [`Skype`, `Blau`, `##ARAL`, `Intel`],
    },
    {
        id: "QHT",
        question: `Welches Unternehmen ist gesucht?`,
        answers: [`Shell`, `Shell`, `McDonald's`, `##LEGO`],
    },
    {
        id: "RFV",
        question: `Welches Logo ist gesucht?`,
        answers: [`Olympische Ringe`, `##Google`, `Pinterest`, `Audi`],
    },
    {
        id: "LRD",
        question: `Welches Unternehmen ist gesucht?`,
        answers: [`##Ferrari `, `Lamborghini`, `Huk Coburg`, `Lotus`],
    },
];

export default image;