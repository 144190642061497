const demo = [
    {
        genre: "knowledge",
        type: "classic",
        question: `Wieviele Buchstaben beinhaltet das Alphabet?`,
        answers: [`22`, `24`, `##26`, `28`],
    },
    {
        genre: "knowledge",
        type: "classic",
        question: `Welches Viereck hat stets vier gleich lange Seiten?`,
        answers: [`Rechteck`, `Trapez`, `Drache`, `##Raute`],
    },
    {
        genre: "personal",
        type: "classic",
        question: `Was würdest du jetzt lieber tun?`,
        answers: [`shoppen`, `schlafen`, `essen`, `fernsehen`],
    },
    {
        genre: "personal",
        type: "scale",
        question: `Wie gut wirst du dieses Spiel meistern?`,
    },
    {
        genre: "personal",
        type: "choice",
        question: `Dies ist die letzte Demo-Frage. Hast du das Spiel verstanden?`,
    },
];

export default demo;