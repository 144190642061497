import {makeAutoObservable, configure} from "mobx";

const develop = false;

//############################################################################# Easing

const easing = {
    smooth: [0.65, 0, 0.35, 1],
    smoothOut: [0.22, 1, 0.36, 1],
    bounce: [0.34, 1.4, 0.3, 1],
    back: [0.68, -0.6, 0.32, 1.6],
}

//############################################################################# Effect

configure({
    enforceActions: "never",
});

class Effect {
    start = {
        init: {
            opacity: 0,
            y: 50,
        },
        in: {
            opacity: 1,
            y: 0,
            transition: { ease: easing.smoothOut, duration: (develop) ? 0.5 : 1 },
        },
        out: {
            opacity: 0,
            y: 50,
            transition: { ease: easing.smooth, duration: 0.3 },
        },
    }

    question = {
        init: {
            opacity: 0,
            scale: 2,
            height: 0,
            margin: "0em 0em",
            filter: "blur(10px)",
        },
        in: {
            opacity: 1,
            scale: 1,
            height: "auto",
            margin: "1em 0em",
            filter: "blur(0px)",
            transition: {
                opacity: { ease: easing.smoothOut, duration: (develop) ? 0.5 : 1.5 },
                height: { ease: easing.smoothOut, duration: (develop) ? 0.5 : 1.5 },
                scale: { ease: easing.bounce, duration: (develop) ? 0.5 : 1.5 },
                filter: { ease: easing.smoothOut, duration: (develop) ? 0.5 : 1.5 },
            },
        },
        out: {
            opacity: 0,
            y: 60,
            filter: "blur(10px)",
            transition: { ease: easing.smooth, duration: (develop) ? 0.5 : 1 },
        },
    }

    image = {
        init: {
            opacity: 0,
            scaleX: 1.2,
            filter: "blur(10px)",
        },
        in: {
            opacity: 1,
            scaleX: 1,
            filter: "blur(0px)",
            transition: { ease: easing.smooth, duration: 0.8, delay: 2 },
        },
        out: {
            opacity: 0,
            filter: "blur(10px)",
            transition: { ease: easing.smooth, duration: (develop) ? 0.5 : 1 },
        }
    }

    personalPlayer = {
        init: {
            opacity: 0,
            y: 50,
        },
        in: {
            opacity: 1,
            y: 0,
            transition: { ease: easing.smoothOut, duration: (develop) ? 0.5 : 1 },
        },
        out: {
            opacity: 0,
            y: 50,
            transition: { ease: easing.smooth, duration: (develop) ? 0.5 : 1 },
        },
    }

    prompt = {
        init: {
            opacity: 0,
            height: 0,
        },
        in: {
            opacity: [1, 0.5],
            height: "4em",
            transition: {
                opacity: { ease: [0.37, 0, 0.63, 1], duration: 0.7, repeat: Infinity, repeatType: "reverse" },
                height: { ease: easing.smooth, duration: 0.7 },
            },
        },
        out: {
            opacity: 0,
            y: 50,
            transition: { ease: easing.smooth, duration: (develop) ? 0.5 : 1 },
        },
        update: {
            opacity: 1,
            height: "4em",
            filter: ["blur(0px)", "blur(20px)", "blur(0px)"],
            transition: { ease: [0.65, 0, 0.35, 1], duration: 1 },
        },
    }

    answerContainer = {
        init: {
            opacity: 0,
            height: 0,
        },
        in: {
            opacity: 1,
            height: "auto",
            transition: {
                opacity: { ease: easing.smooth, duration: (develop) ? 0.5 : 1, delay: (develop) ? 0.5 : 2.5 },
                height: { ease: easing.smoothOut, duration: (develop) ? 0.5 : 1.2, delay: (develop) ? 0.5 : 2.5 },
                when: "beforeChildren",
                staggerChildren: (develop) ? 0.5 : 0.8,
            },
        },
        out: {
            opacity: 0,
            y: 50,
            transition: { ease: easing.smooth, duration: (develop) ? 0.5 : 1 },
        },
    }

    answer = {
        init: {
            opacity: 0,
        },
        in: {
            opacity: 1,
            transition: { ease: easing.smooth, duration: 0.3 },
        },
    }

    player = {
        default: {
            win: {
                transformOrigin: "50% 50%",
                x: "-50%",
                y: [0, 5, -5, 5, -5, 5, -5, 0],
                transition: {
                    y: { ease: [0.37, 0, 0.63, 1], duration: 2.5 },
                },
            },
            lose: {
                transformOrigin: "50% 50%",
                x: ["-50%", "-54%", "-46%", "-54%", "-46%", "-50%"],
                y: [0, 3, -3, 3, -3, 3, -3, 0],
                transition: {
                    x: { ease: [0.65, 0, 0.35, 1], duration: 1.5 },
                    y: { ease: [0.65, 0, 0.35, 1], duration: 1 },
                },
            },
        },
        christmas: {
            win: {
                transformOrigin: "50% 50%",
                x: "-50%",
                rotate: [0, 20, -20, 20, -20, 0],
                transition: {
                    rotate: { ease: [0.37, 0, 0.63, 1], duration: 2.5 },
                },
            },
            lose: {
                transformOrigin: "50% 100%",
                x: "-50%",
                rotate: [0, 50, 0],
                transition: {
                    rotate: { ease: [0.65, 0, 0.35, 1], duration: 2.5 },
                },
            },
        },
        easter: {
            win: {
                transformOrigin: "50% 50%",
                x: "-50%",
                y: [0, 3, -3, 3, -15, 3, 0],
                rotate: [0, 3, -3, 3, -3, 3, -3, 0],
                transition: { ease: [0.37, 0, 0.63, 1], duration: 2.5 },
            },
            lose: {
                transformOrigin: "50% 100%",
                x: "-50%",
                y: [0, -15, 0],
                rotate: [0, 5, -5, 5, -5, 0],
                transition: {ease: [0.65, 0, 0.35, 1], duration: 1 },
            },
        },
    }

    joker = {
        init: {
            opacity: 1,
            scale: 0,
            x: "-50%",
            y: "-50%",
        },
        in: {
            opacity: [1, 1, 0],
            scale: 2.8,
            x: "-50%",
            y: "-50%",
            transition: { ease: easing.smoothOut, duration: 4 },
        },
    }

    jokerIndicator = {
        init: {
            opacity: 0,
        },
        in: {
            opacity: 1,
            transition: {
                ease: easing.smooth,
                duration: 1,
            },
        },
        out: {
            opacity: 0,
            x: -50,
            transition: { ease: easing.smooth, duration: 1 },
        },
    }

    jokerCard = {
        init: {
            rotateY: 0,
        },
        in: {
            rotateY: 360,
            transition: { ease: easing.smooth, duration: 2 },
        },
        inactive: {
            rotateY: 360,
        },
    }

    final = {
        init: {
            x: window.outerWidth / 2 + 500,
        },
        in: {
            x: 0,
            transition: { ease: [0.22, 1, 0.36, 1], duration: 2 },
        },
        out: {
            x: -(window.outerWidth / 2 + 500),
            transition: { ease: [0.64, 0, 0.78, 0], duration: 1.5, delay: 0.5 },
        },
    }

    finalEvaluation = {
        init: {
            opacity: 0,
            y: 50,
        },
        in: {
            opacity: 1,
            y: 0,
            transition: {
                ease: easing.smoothOut,
                duration: 1,
                delay: 1,
                when: "beforeChildren",
                staggerChildren: 0.5,
             },
        },
        out: {
            opacity: 0,
            y: 50,
            transition: { ease: easing.smooth, duration: (develop) ? 0.5 : 1 },
        },
    }

    finalEvaluationAnswer = {
        init: {
            opacity: 0,
            y: 30,
        },
        in: {
            opacity: 1,
            y: 0,
            transition: { ease: easing.smooth, duration: 0.5 },
        },
    }

    finalCorrectAnswer = {
        init: {
            opacity: 0,
            height: 0,
            marginTop: 0,
        },
        in: {
            opacity: 1,
            height: "auto",
            marginTop: "2.5em",
            transition: {ease: easing.smoothOut, duration: 1.5, delay: 0.5 },
        },
    }

    winner = {
        init: {
            opacity: 0,
            scale: 0,
            rotate: 360,
        },
        in: {
            opacity: 1,
            scale: 1,
            rotate: 0,
            transition: { ease: easing.bounce, duration: 2 },
        },
        out: {
            opacity: 0,
            y: 50,
            transition: { ease: easing.smooth, duration: (develop) ? 0.5 : 1 },
        },
    }

    imagePreview = {
        init: {
            opacity: 0,
            filter: "blur(10px)",
        },
        in: {
            opacity: 1,
            filter: "blur(0px)",
            transition: { ease: easing.smoothOut, duration: 1 },
        },
        out: {
            opacity: 0,
            filter: "blur(10px)",
            transition: { ease: easing.smoothOut, duration: 1 },
        },
    }

    settings = {
        init: {
            opacity: 0,
            scale: 0.8,
            y: "-50%",
        },
        in: {
            opacity: 1,
            scale: 1,
            y: "-50%",
            transition: { ease: easing.smoothOut, duration: 0.5 },
        },
        out: {
            opacity: 0,
            scale: 0.8,
            y: "-50%",
            transition: { ease: easing.smoothOut, duration: 0.5 },
        },
    }

    constructor() {
        makeAutoObservable(this);
    }
}

const effect = new Effect();

export default effect;