import global from "../components/Global";

//############################################################################# Audio

function play(name = "", play = true) {
    const {audio} = global;
    const {theme} = global.settings;
    const type = Object.keys(audio.type).find(key => audio.type[key].includes(name));

    if (!type) return;

    let number = "";
    if (name === "clapping") number = random(7, 1);
    if (name === "aww") number = random(3, 1);

    let volume = audio.volume[name];
    let path = `./audio/${type}/${name}${number}.ogg`;

    if (type === "player") {
        volume = audio.volume.player[theme][name];
        path = `./audio/${type}/${theme}/${name}.ogg`;
    }

    if (type === "music") {
        volume = audio.volume.music[theme];
        path = `./audio/${type}/${theme}.ogg`;
    }

    const audioFile = new Audio(path);

    if (type === "music") audioFile.loop = true;
    audioFile.volume = volume || 1.0;
    
    if (play) audioFile.play();

    return audioFile;
}

//############################################################################# Array Functions

function random(max = 0, min = 0) {
    if (Array.isArray(max)) {
        const index = Math.floor(Math.random() * ((max.length - 1) - min + 1) + min);
        return max[index] || null;
    } else {
        return Math.floor(Math.random() * (max - min + 1) + min);
    }
}

function shuffle(list = []) {
    for (let i = list.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [list[i], list[j]] = [list[j], list[i]];
    }
    return list;
}

//############################################################################# Get Team Index

function getNextTeamIndex(type) {
    const {teams, round} = global;
    const teamIndex = teams.indexOf(round.team);
    let index = null;

    for (let i = 0; i < teams.length; i++) {
        if (i <= teamIndex) continue;
        if (!type) { index = i; break; };
        if (type === "match" && !teams[i].final) { index = i; break; };
        if (type === "final" && teams[i].final) { index = i; break; };
    }

    if (!index) {
        for (let i = 0; i < teams.length; i++) {
            if (i > teamIndex) continue;
            if (!type) { index = i; break; };
            // Search index (for match only) until last team (not starting at beginning again)
            // if (type === "match" && !teams[i].final) { index = i; break; };
            if (type === "final" && teams[i].final) { index = i; break; };
        }
    }

    return (index !== null) ? index : null;
}

function getFirstTeamIndex(type) {
    const {teams} = global;

    for (let i = 0; i < teams.length; i++) {
        if (!type) return i;
        if (type === "match" && !teams[i].final) return i;
        if (type === "final" && teams[i].final) return i;
    }

    return 0;
}

//############################################################################# Save Data

function saveData(type) {
    const {game, round, teams} = global;

    if (type === "questions") localStorage.setItem("quizQuestions", JSON.stringify(game.questions));
    if (type === "teams") localStorage.setItem("quizTeams", JSON.stringify(teams));
    if (type === "turn") localStorage.setItem("quizTeamTurn", (round.team) ? teams.indexOf(round.team) : null);;
}

//############################################################################# Fullscreen

function fullscreen() {
    if (isFullscreen()) {
        exitFullscreen();
    } else {
        enterFullscreen();
    }
}

function isFullscreen() {
    return document.fullscreenElement !== null;
}

function enterFullscreen() {
    const element = document.documentElement;

    if (element.requestFullscreen) {
        element.requestFullscreen();
    } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
    } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
    } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen();
    }
}
    
function exitFullscreen() {
    if (isFullscreen()) {
        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen();
        }
    }
}

//############################################################################# Export

export {getNextTeamIndex, getFirstTeamIndex, random, shuffle, saveData, play, fullscreen};