import React, {Component} from "react";
import {observer} from "mobx-react";
import {motion, AnimatePresence} from "framer-motion";

import global from "./Global";
import effect from "../js/animation";
import {chooseQuestion} from "../js/game";
import {play} from "../js/functions";

import Prompt from "./Prompt";
import FinalEvaluation from "./FinalEvaluation";

class Question extends Component {
    render() {
        const {game, round, control, develop} = global;

        return (
            <div id="question-container">

                <AnimatePresence>
                {
                control.personalPlayer &&
                    <motion.div
                        id="personal-player"
                        variants={effect.personalPlayer}
                        initial="init"
                        animate="in"
                        exit="out"
                        onAnimationComplete={(type) => {
                            if (type === "in") {
                                setTimeout(() => { global.control.question = true }, (develop) ? 0 : 1000);
                            }
                        }}
                    >
                        <h1>{ round.personalPlayer } ...</h1>
                    </motion.div>
                }
                </AnimatePresence>

                {
                (!control.final && !control.finalEvaluation && !control.animation.finalAnswerChoosen && !game.winner) &&
                    <div id="question">
                        <AnimatePresence>
                        {
                        control.question &&
                            <motion.h1
                                id="question"
                                variants={effect.question}
                                initial="init"
                                animate="in"
                                exit="out"
                                onAnimationStart={(type) => {
                                    if (type === "in") play("question");
                                }}
                                onAnimationComplete={(type) => {
                                    if (type === "in") global.control.answerContainer = true;
                                }}
                            >
                                { round.question?.question }
                            </motion.h1>
                        }
                        </AnimatePresence>
                        <AnimatePresence>
                        {
                        (control.question && round.question?.genre === "image") &&
                            <motion.img
                                src={`../images/question/${round.question?.id}.jpg`}
                                alt=""
                                variants={effect.image}
                                initial="init"
                                animate="in"
                                exit="out"
                            ></motion.img>
                        }
                        </AnimatePresence>
                    </div>
                }

                <AnimatePresence>{ control.prompt && <Prompt/> }</AnimatePresence>

                <AnimatePresence>
                {
                control.final &&
                    <motion.div
                        id="final"
                        variants={effect.final}
                        initial="init"
                        animate="in"
                        exit="out"
                        onAnimationStart={(type) => {
                            if (type === "in") play("final");
                        }}
                        onAnimationComplete={(type) => {
                            if (type === "in") global.control.final = false;
                            if (type === "out") {
                                setTimeout(() => {
                                    global.round.question = chooseQuestion();
                                }, 1500);
                            }
                        }}
                    >
                        <h1><i className="fad fa-flag-checkered"></i> FINALE</h1>
                    </motion.div>
                }
                </AnimatePresence>

                <AnimatePresence>
                    { control.finalEvaluation && <FinalEvaluation/> }
                </AnimatePresence>

                <AnimatePresence>
                {
                game.winner &&
                    <motion.div
                        id="winner"
                        variants={effect.winner}
                        initial="init"
                        animate="in"
                        exit="out"
                    >
                        <h1>
                            {game.winner?.players?.join(", ")}
                            <img src="../images/crown.png" alt=""/>
                        </h1>
                        <h2>{(game.winner?.players?.length === 1) ? "hat" : "haben"} gewonnen!</h2>
                    </motion.div>
                }
                </AnimatePresence>
            </div>
        );
    }
}

export default observer(Question);