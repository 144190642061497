import React, {Component} from "react";
import {observable} from "mobx";
import {observer} from "mobx-react";
import {ReactSortable} from "react-sortablejs";
import {motion} from "framer-motion";

import global from "./Global";
import effect from "../js/animation";
import {saveData, play} from "../js/functions";

//############################################################################# App

class Settings extends Component {
    localstate = observable({
        teamsInput: global.teams.map(team => team.players.join(", ")),
    });

    addTeam() {
        const {teamTemplate} = global;

        global.teams.push({...teamTemplate});

        setTimeout(() => {
            const teamElements = document.querySelectorAll(".team");
            if (teamElements.length > 0) {
                teamElements[teamElements.length - 1].querySelector("input")?.focus();
            }
        }, 100);
    }

    radioChange({ target }) {
        const {music} = global;
        const musicPaused = (music) ? music.paused : true;

        if (music) music.pause();

        global.settings.theme = target.value;
        global.music = play("music", !musicPaused);
    }

    render() {
        const {teams, settings} = global;
        const {teamsInput} = this.localstate;

        return (
            <motion.div id="settings" variants={effect.settings} initial="init" animate="in" exit="out">
                <div className="flex-row">
                    <div className="radio-box">
                        <label>
                            <input type="radio" name="theme" value="default" checked={settings.theme === "default"} onChange={this.radioChange.bind(this)}/>
                            <p>Standard</p>
                        </label>
                        <label>
                            <input type="radio" name="theme" value="christmas" checked={settings.theme === "christmas"} onChange={this.radioChange.bind(this)}/>
                            <p>Weihnachten</p>
                        </label>
                        <label>
                            <input type="radio" name="theme" value="easter" checked={settings.theme === "easter"} onChange={this.radioChange.bind(this)}/>
                            <p>Ostern</p>
                        </label>
                    </div>
                </div>

                <div className="flex-col">
                    <ReactSortable
                        list={teams}
                        handle=".move"
                        direction="vertical"
                        delay={2}
                        animation={200}
                        delayOnTouchStart={true}
                        setList={(newList) => {
                            global.teams = newList;

                            for (const team of global.teams) {
                                delete team.chosen;
                                delete team.selected;
                            }

                            this.localstate.teamsInput = global.teams.map(team => team.players.join(", "));
                            saveData("teams");
                        }}
                    >
                    {
                        teams.map((team, i) => (
                            <div key={i} className="team">
                                <button
                                    className="remove btn-icon"
                                    tabIndex="-1"
                                    onClick={() => {
                                        global.teams.splice(i, 1);
                                        this.localstate.teamsInput.splice(i, 1);
                                        saveData("teams");
                                    }}
                                >
                                    <i className="fas fa-trash-alt"></i>
                                </button>
                                <button className="move btn-icon" tabIndex="-1"><i className="fas fa-grip"></i></button>
                                <input
                                    type="text"
                                    maxLength="30"
                                    value={teamsInput[i] || ""}
                                    placeholder="Namen mit , trennen"
                                    onChange={(e) => this.localstate.teamsInput[i] = e.target.value}
                                    onBlur={(e) => {
                                        team.players = e.target.value.replace(/\s+,|.\s+/g, ",").split(",").filter(x => x !== "");
                                        saveData("teams");
                                    }}
                                />
                            </div>
                        ))
                    }
                    </ReactSortable>
                </div>

                <div className="flex-row">
                    <button onClick={() => this.addTeam()}><i className="fal fa-plus"></i><span>Hinzufügen</span></button>
                    <button onClick={() => global.control.settings = false}><i className="fal fa-xmark"></i><span>Schließen</span></button>
                </div>
            </motion.div>
        );
    }
}

export default observer(Settings);