import {makeAutoObservable, configure} from "mobx";

import {chooseAnswer, useJoker, toggleImagePreview} from "../js/game";

configure({
    enforceActions: "never",
});

class Global {
    develop = false;

    settings = {
        theme: "default", // default | christmas | easter
        scoreMax: 8,
        jokerPerTeam: 2,
        allowJokerFromAnswers: 4, // min: 3
        forcePersonalQuestion: 5, // Every 5th question must be a personal, if not before already
        maxPersonalPerTeam: 4, // maximal number of personal questions answered
    }

    game = {
        status: "start", // start | demo | match | final
        questions: [],
        allowInput: false,
        winner: null,
        notPersonalCounter: 0,
        move: 0,
    }

    round = {
        team: null,
        question: null,
        personalPlayer: null,
        jokerAnswers: [],
        jokerUsed: false,
        answer: {
            personal: null,
            choosen: null,
            correct: null,
            final: [],
        },
    }
    
    teams = [];

    teamTemplate = {
        players: [],
        score: 0,
        joker: this.settings.jokerPerTeam,
        final: false,
        lastPersonalPlayer: null,
        personalCounter: 0,
    }

    music = null;

    control = {
        question: false,
        imagePreview: false,
        answerContainer: false,
        answers: false,
        prompt: false,
        promptText: false,
        joker: false,
        jokerIndicator: false,
        progressFinal: false,
        final: false,
        finalEvaluation: false,
        finalWinner: false,
        finalCorrectAnswer: false,
        settings: false,
        animation: {
            player: null,
            finalAnswerChoosen: false,
            finalAnswer: [],
        },
    }

    audio = {
        type: {
            audience: ["aww", "clapping", "clappingFinal"],
            interface: ["choosen", "correct", "joker", "logged", "question", "wrong", "final"],
            player: ["win", "lose"],
            music: ["music"],
        },
        volume: {
            aww: 0.7,
            clapping: 0.9,
            clappingFinal: 0.8,
            choosen: 0.5,
            correct: 0.8,
            wrong: 1.0,
            question: 0.6,
            logged: 0.8,
            joker: 0.7,
            final: 0.7,
            player: {
                default: {
                    win: 1.0,
                    lose: 1.0,
                },
                christmas: {
                    win: 0.8,
                    lose: 0.7,
                },
                easter: {
                    win: 0.9,
                    lose: 0.9,
                },
            },
            music: {
                default: 0.1,
                christmas: 0.1,
                easter: 0.1,
            },
        },
    }

    constructor() {
        makeAutoObservable(this);
    }
}

const global = new Global();

//############################################################################# Events

document.addEventListener("keydown", (e) => {
    if (e.key === "1" || e.code === "Numpad1") chooseAnswer(0);
    if (e.key === "2" || e.code === "Numpad2") chooseAnswer(1);
    if (e.key === "3" || e.code === "Numpad3") chooseAnswer(2);
    if (e.key === "4" || e.code === "Numpad4") chooseAnswer(3);
    if (e.key === "*" || e.code === "NumpadMultiply") useJoker();
    if (e.key === "Enter" || e.code === "NumpadEnter") toggleImagePreview();
});

//############################################################################# Export

export default global;