import React, {Component} from "react";
import {observer} from "mobx-react";
import {AnimatePresence, motion, useAnimationControls} from "framer-motion";

import global from "./Global";
import effect from "../js/animation";
import {chooseQuestion, jokerEffect, nextRound, reset} from "../js/game";
import {fullscreen, play} from "../js/functions";

import Question from "./Question";
import Answer from "./Answer";
import Settings from "./Settings";

//############################################################################# App

class App extends Component {
    start(status = "match") {
        const {settings, music} = global;

        if (!music) {
            global.music = play("music");
        } else {
            music.play();
        }

        global.game.status = status;
        this.setStorageData();

        if (status === "demo") {
            for (const team of global.teams) {
                team.score = 0;
                team.final = false;
                team.joker = 2;
            }
            setTimeout(() => {
                global.round.question = chooseQuestion()
            }, 1000);
            return;
        }

        if (global.round.team?.final && global.round.team?.score >= settings.scoreMax - 1) {
            global.game.status = "final";
            global.control.final = true;
        } else {
            setTimeout(() => {
                global.round.question = chooseQuestion()
            }, 1000);
        }
    }

    toggleMusic() {
        const {music} = global;

        if (!music) {
            global.music = play("music");
        } else {
            if (music.paused) {
                music.play();
            } else {
                music.pause();
            }
        }
    }

    setStorageData(startup = false) {
        const {game, teams} = global;

        const storage = {
            teams: localStorage.getItem("quizTeams"),
            questions: localStorage.getItem("quizQuestions"),
            turn: localStorage.getItem("quizTeamTurn"),
        }

        const saved = {
            teams: storage.teams ? JSON.parse(storage.teams) : teams,
            questions: storage.questions ? JSON.parse(storage.questions) : [],
            turn: storage.turn ? Number(storage.turn) : null,
        }

        global.teams = saved.teams;
        global.game.questions = saved.questions;
        if (!startup) global.round.team = (saved.turn && game.status !== "demo") ? global.teams[saved.turn] : global.teams[0];
    }

    componentDidMount() {
        this.setStorageData(true);
        if (global.teams.length === 0) {
            global.control.settings = true;
        }
    }

    render() {
        const {game, settings, teams, round, control} = global;
        const teamsInFinal = teams.filter(team => team.final);

        return (
            <div id="page" data-theme={settings.theme}>
                <div id="team-container">
                    <div id="player-container">
                    {
                        teams.map((team, i) => {
                            const final = team.final && ((round.team === team && control.progressFinal) || round.team !== team || game.status === "final");
                            const gameover = game.status === "final" && !team.final && teamsInFinal.length >= 2;

                            return (
                                <div
                                    key={i}
                                    className={
                                        "player" +
                                        `${(final) ? " final" : ""}` +
                                        `${(gameover) ? " gameover" : ""}`
                                    }
                                    {...(team === round.team && { "data-turn": "" })}
                                >
                                    <h2>{ team.players?.join(", ") }</h2>
                                </div>
                            );
                        })
                    }
                    </div>
                    <div id="progress-container">
                    {
                        teams.map((team, i) => {
                            const final = team.final && ((round.team === team && control.progressFinal) || round.team !== team || game.status === "final");
                            const gameover = game.status === "final" && !team.final && teamsInFinal.length >= 2;
                            const scorePercent = team.score * (100 / settings.scoreMax);

                            return (
                                <div
                                    key={i}
                                    className={
                                        "progress-line" +
                                        `${(final) ? " final" : ""}` +
                                        `${(gameover) ? " gameover" : ""}`
                                    }
                                    {...(team === round.team && { "data-turn": "" })}
                                >
                                    <motion.img
                                        className="player-icon"
                                        src={`../images/player/${settings.theme}.png`}
                                        style={{ x: "-50%", left: `${scorePercent}%` }}
                                        data-theme={settings.theme}
                                        animate={team === round.team && control.animation.player}
                                        onAnimationComplete={() => {
                                            global.control.progressFinal = true;
                                            const delay = (round.team.score === settings.scoreMax - 1) ? 2000 : 500;
                                            if (!game.winner) setTimeout(() => { nextRound() }, delay);
                                        }}
                                    />

                                    {
                                        Array.from(Array(settings.scoreMax + 1), (x, i) => (
                                            <div key={i} className="step"></div>
                                        ))
                                    }

                                    <div className="progress-final" style={{ right: `${100 / settings.scoreMax}%` }}>
                                        <i className="fas fa-flag-checkered"></i>
                                        <h2>Finale</h2>
                                    </div>

                                    <i className="icon-trophy fas fa-trophy"></i>
                                </div>
                            );
                        })
                    }
                    </div>
                    <AnimatePresence>
                    {
                    (control.jokerIndicator && game.status !== "final") &&
                        <motion.div
                            id="joker-indicator"
                            className={
                                (round.team?.joker <= 0)
                                    ? "empty"
                                    : (round.question?.answers?.length < settings.allowJokerFromAnswers)
                                        ? "disabled"
                                        : null
                            }
                            variants={effect.jokerIndicator}
                            initial="init"
                            animate="in"
                            exit="out"
                        >
                            <motion.div
                                id="joker-card"
                                className={(round.team?.joker === 0) ? "empty" : null}
                                variants={effect.jokerCard}
                                initial="init"
                                animate={(round.team?.joker > 0 && round.question?.answers?.length >= settings.allowJokerFromAnswers) ? "in" : "inactive"}
                            >
                                <i className="fas fa-crown"></i>
                                <p>50:50</p>
                            </motion.div>
                            <h2>{round.team?.joker}x</h2>
                        </motion.div>
                    }
                    </AnimatePresence>
                </div>

                <Question/>

                <Answer/>

                <AnimatePresence>
                {
                game.status === "start" &&
                    <motion.div
                        id="start"
                        variants={effect.start}
                        initial="init"
                        animate="in"
                        exit="out"
                    >
                        <div className="flex-row">
                            <button onClick={() => this.start()}>
                                <span>Start</span>
                            </button>
                            <button onClick={() => this.start("demo")}>
                                <span>Demo</span>
                            </button>
                        </div>
                    </motion.div>
                }
                </AnimatePresence>

                {
                control.joker &&
                    <motion.div
                        id="joker"
                        variants={effect.joker}
                        initial="init"
                        animate="in"
                        onAnimationComplete={() => jokerEffect()}
                    >
                        <h1>50:50</h1>
                    </motion.div>
                }

                <AnimatePresence>
                {
                control.imagePreview &&
                    <motion.div
                        id="image-preview"
                        variants={effect.imagePreview}
                        initial="init"
                        animate="in"
                        exit="out"
                    >
                        <img src={`../images/question/${round.question?.id}.jpg`} alt=""/>
                    </motion.div>
                }
                </AnimatePresence>

                <AnimatePresence>
                    { control.settings && <Settings/> }
                </AnimatePresence>

                <div id="settings-buttons">
                    <button className="btn-icon" title="Teams einstellen" onClick={() => global.control.settings = !control.settings}>
                        <i className="fal fa-cog"></i>
                    </button>
                    <button className="btn-icon" title="Hintergrundmusik ein/aus" onClick={() => this.toggleMusic()}>
                        <i className="fal fa-music"></i>
                    </button>
                    <button className="btn-icon" title="Alle gespielten Fragen zurücksetzen" onClick={() => reset("questions")}>
                        <i className="fal fa-arrow-rotate-left"></i>
                    </button>
                    <button className="btn-icon" title="Gespeicherten Spielstand zurücksetzen" onClick={() => reset("teams")}>
                        <i className="fal fa-trash-alt"></i>
                    </button>
                    <button className="btn-icon" onClick={() => fullscreen()}>
                        <i className="fal fa-expand"></i>
                    </button>
                </div>
            </div>
        );
    }
}

App = observer(App);

const ClassWrapper = () => {
    global.control.animation.player = useAnimationControls();
    return <App/>;
}

export default ClassWrapper;