import React, {Component} from "react";
import {observable} from "mobx";
import {observer} from "mobx-react";
import {motion} from "framer-motion";

import global from "./Global";
import effect from "../js/animation";

class Prompt extends Component {
    localstate = observable({
        text: null,
        update: false,
    });

    componentDidUpdate() {
        const {control} = global;
        
        if (control.promptText && control.promptText !== this.localstate.text) {
            this.localstate.update = true;
        }
    }

    render() {
        const {control} = global;
        const {text, update} = this.localstate;

        if (control.promptText) {
            setTimeout(() => { this.localstate.text = control.promptText }, 300);
        }

        return (
            <motion.div
                id="prompt"
                variants={effect.prompt}
                initial="init"
                animate={update ? "update" : "in"}
                exit="out"
                onAnimationComplete={(type) => {
                    if (type === "update") this.localstate.update = false;
                }}
            >
                <h2 dangerouslySetInnerHTML={{ __html: text || "Wähle jetzt deine Antwort" }}></h2>
            </motion.div>
        );
    }
}

export default observer(Prompt);